import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // Small timeout to ensure DOM is ready and any animations are complete
    setTimeout(() => {
      this.element.focus()
    }, 100)
  }
}
