import { Controller } from 'stimulus';
import { gsap } from 'gsap';

/**
 * @extends {Controller}
 * @property {boolean} hasPanelTarget
 * @property {HTMLElement} panelTarget
 * @property {boolean} hasModalTarget
 * @property {HTMLElement} modalTarget
 * @property {boolean} hasDialogTarget
 * @property {HTMLElement} dialogTarget
 * @property {boolean} hasSpinnerTarget
 * @property {HTMLElement} spinnerTarget
 * @property {boolean} hasTurboFrameTarget
 * @property {HTMLElement} turboFrameTarget
 */
export default class extends Controller {
  /** @type {string[]} */
  static targets = [
    'modal',
    'dialog',
    'panel',
    'spinner',
    'turboFrame'
  ]

  connect() {
    this.boundDialogClick = this.dialogClick.bind(this);
    this.boundEscapePress = this.escapePress.bind(this);
    this.boundTurboStreamHandler = this.handleTurboStream.bind(this);
    document.addEventListener(
      'turbo:before-stream-render',
      this.boundTurboStreamHandler
    );
  }

  disconnect() {
    document.removeEventListener('keydown', this.boundEscapePress);
    document.removeEventListener('click', this.boundDialogClick);
    document.removeEventListener(
      'turbo:before-stream-render',
      this.boundTurboStreamHandler
    );
    document.body.style.overflow = '';
  }

  handleTurboStream(event) {
    const { action, target } = event.detail.newStream;
    console.log(action, target);

    if (target === 'trade_modal_panel' && action === 'replace') {
      event.preventDefault();
      this.handlePanelAdditionOrReplacement(event.detail.newStream);
    }
  }

  removeModalListeners() {
    document.removeEventListener('keydown', this.boundEscapePress);
    this.modalTarget.removeEventListener('mousedown', this.boundDialogClick);
  }

  handlePanelAdditionOrReplacement(newStream) {
    const currentTurboFrame = this.turboFrameTarget.firstElementChild
    const newTurboFrame = newStream.querySelector('template').content.querySelector('turbo-frame')
    this.updateContent(currentTurboFrame, newTurboFrame)
  }

  renderNewTurboFrame(event) {
    event.detail.render = (currentElement, newElement) => {
      const currentTurboFrame = currentElement.querySelector('turbo-frame')
      const newTurboFrame = newElement.querySelector('turbo-frame')

      this.updateContent(currentTurboFrame, newTurboFrame)
    }
  }

  updateContent(currentTurboFrame, newTurboFrame) {
    const isMobile = window.matchMedia('(max-width: 640px)').matches;

    gsap.to(this.spinnerTarget, {
      opacity: 0,
      duration: 0.2,
      ease: 'power1.out',
      onComplete: () => {
        this.spinnerTarget.classList.add('hidden');
      }
    });

    // Check if the close modal partial is passed, if it is close the modal
    if (newTurboFrame.dataset.modal === 'close') {
      this.closeModal();
      return;
    }

    // Important: Remove existing listeners before checking if we should lock
    this.removeModalListeners();

    // Only add listeners if we're not locking the modal
    if (newTurboFrame.dataset.lockModal !== 'true') {
      this.modalTarget.addEventListener('mousedown', this.boundDialogClick);
      document.addEventListener('keydown', this.boundEscapePress);
    }

    if (!currentTurboFrame) {
      this.openModal(null, { lockModal: newTurboFrame.dataset.lockModal === 'true' });
      // Rest of the code remains the same...
      this.turboFrameTarget.classList.remove('hidden');

      gsap.set(this.turboFrameTarget, {
        opacity: 0,
        y: isMobile ? 16 : 0,
        scale: isMobile ? 1 : 0.95
      });

      this.turboFrameTarget.appendChild(newTurboFrame);

      gsap.to(this.turboFrameTarget, {
        opacity: 1,
        y: 0,
        scale: 1,
        duration: 0.2,
        ease: 'power1.out'
      });
    } else {
      // Create a temporary container that matches the turboFrame's styling
      const tempContainer = document.createElement('div');
      tempContainer.style.position = 'absolute';
      tempContainer.style.visibility = 'hidden';
      tempContainer.style.padding = getComputedStyle(this.turboFrameTarget).padding;
      tempContainer.className = this.turboFrameTarget.className;
      tempContainer.classList.remove('hidden'); // Ensure visibility for measurement

      // Clone and append the new content
      tempContainer.appendChild(newTurboFrame.cloneNode(true));
      document.body.appendChild(tempContainer);

      // Get the full dimensions including padding
      const newHeight = tempContainer.getBoundingClientRect().height;
      const newWidth = tempContainer.getBoundingClientRect().width;

      // Clean up
      document.body.removeChild(tempContainer);

      // Animate out current panel
      gsap.to(currentTurboFrame, {
        opacity: 0,
        y: isMobile ? 16 : 0,
        scale: isMobile ? 1 : 0.95,
        duration: 0.2,
        ease: 'power1.in',
        onComplete: () => {
          // Set initial state for new content
          gsap.set(newTurboFrame, {
            opacity: 0,
            y: isMobile ? 16 : 0,
            scale: isMobile ? 1 : 0.95
          });

          // Animate turboFrame to new dimensions
          gsap.to(this.turboFrameTarget, {
            width: newWidth,
            height: newHeight,
            duration: 0.2,
            ease: 'power1.inOut',
            onComplete: () => {
              // Replace with new content
              this.turboFrameTarget.innerHTML = '';
              this.turboFrameTarget.appendChild(newTurboFrame);
              this.turboFrameTarget.style.width = '';
              this.turboFrameTarget.style.height = '';

              // Animate in new content
              gsap.to(newTurboFrame, {
                opacity: 1,
                y: 0,
                scale: 1,
                duration: 0.2,
                ease: 'power1.out'
              });
            }
          });
        }
      });
    }
  }

  dialogClick = (e) => {
    console.log('dialog click');
    if (this.hasTurboFrameTarget && this.turboFrameTarget.contains(e.target)) {
      return;
    }

    this.closeModal();
  };

  escapePress = (e) => {
    if (e.key === 'Escape') {
      this.closeModal();
    }
  };

  openModal(e, options = {}) {
    this.lastActiveElement = document.activeElement;
    document.body.style.overflow = 'hidden';

    // Remove existing listeners first
    this.removeModalListeners();

    // Only add listeners if we're not locking the modal
    if (!options.lockModal && e?.currentTarget?.dataset?.lockModal !== 'true') {
      this.modalTarget.addEventListener('mousedown', this.boundDialogClick);
      document.addEventListener('keydown', this.boundEscapePress);
    }

    gsap.set(this.spinnerTarget, { opacity: 1 });
    this.spinnerTarget.classList.remove('hidden');
    this.modalTarget.classList.remove('hidden');
    this.dialogTarget.classList.remove('hidden');
    gsap.set(this.dialogTarget, { opacity: 0 });

    gsap.to(this.dialogTarget, {
      opacity: 1,
      duration: 0.2,
      ease: 'power1.out'
    });
  }

  closeModal(e) {
    this.removeModalListeners();

    const isMobile = window.matchMedia('(max-width: 640px)').matches;

    this.spinnerTarget.classList.add('hidden');

    gsap.to(this.turboFrameTarget, {
      opacity: 0,
      y: isMobile ? 16 : 0,
      scale: isMobile ? 1 : 0.95,
      duration: 0.2,
      ease: 'power1.in'
    });

    gsap.to(this.dialogTarget, {
      opacity: 0,
      duration: 0.2,
      ease: 'power1.out',
      onComplete: () => {
        this.modalTarget.classList.add('hidden');
        this.dialogTarget.classList.add('hidden');
        this.spinnerTarget.classList.add('hidden');
        this.turboFrameTarget.classList.add('hidden');
        this.turboFrameTarget.innerHTML = '';

        this.turboFrameTarget.style.width = '';
        this.turboFrameTarget.style.height = '';

        document.body.style.overflow = '';

        if (this.lastActiveElement) {
          this.lastActiveElement.focus();
        }
      }
    });
  }
}
