// hover_animation_controller.js
import { Controller } from "@hotwired/stimulus"
import gsap from "gsap"

export default class extends Controller {
  connect() {
    this.defaultGradient = `radial-gradient(167.79% 167.79% at 100% 0%, rgba(16, 25, 26, 0.80) 0%, rgba(16, 25, 26, 0.00) 87.47%), radial-gradient(104.38% 128.73% at 0% 0%, rgba(254, 249, 231, 0.20) 0%, rgba(244, 235, 255, 0.20) 85.89%, rgba(219, 242, 255, 0.20) 100%), #10191A`

    this.hoverGradient = `radial-gradient(167.79% 167.79% at 100% 0%, rgba(16, 25, 26, 0.80) 0%, rgba(16, 25, 26, 0.00) 87.47%), radial-gradient(104.38% 128.73% at 0% 0%, rgba(254, 249, 231, 0.02) 0%, rgba(244, 235, 255, 0.02) 85.89%, rgba(219, 242, 255, 0.02) 100%), #10191A`

    // Set initial state
    this.element.style.background = this.defaultGradient

    // Bind the event handlers
    this.boundOnHover = this.onHover.bind(this)
    this.boundOnLeave = this.onLeave.bind(this)

    // Set up event listeners
    this.element.addEventListener("mouseenter", this.boundOnHover)
    this.element.addEventListener("mouseleave", this.boundOnLeave)
  }

  disconnect() {
    this.element.removeEventListener("mouseenter", this.boundOnHover)
    this.element.removeEventListener("mouseleave", this.boundOnLeave)
    gsap.killTweensOf(this.element)
  }

  onHover() {
    gsap.to(this.element, {
      duration: 0.1,
      background: this.hoverGradient,
      ease: "none"
    })
  }

  onLeave() {
    gsap.to(this.element, {
      duration: 0.1,
      background: this.defaultGradient,
      ease: "none"
    })
  }
}
