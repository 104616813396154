import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["label", "input", "text"]

  connect() {
    // Set initial states
    console.log('connected')
    this.updateStyles()
    if(this.hasTextTarget) {
      this.textTarget.focus()
    }
  }

  inputTargetConnected(input) {
    // Set initial state for newly connected inputs
    this.updateLabelStyle(input)
  }

  // Handle changes on radio buttons and checkboxes
  change(event) {
    if (event.target.type === 'radio') {
      // For radio buttons, we need to reset all and then set the checked one
      this.labelTargets.forEach(label => {
        this.resetLabelStyle(label)
      })
    }

    // Update the style for the changed input's label
    this.updateLabelStyle(event.target)
  }

  // Update styles for a single input's label
  updateLabelStyle(input) {
    const label = input.closest('[data-due-diligence-input-target="label"]')
    if (!label) return

    if (input.checked) {
      label.classList.remove('bg-card-background-alternative', 'border-gray-600')
      label.classList.add('bg-gray-600', 'border-gray-500')
    } else {
      this.resetLabelStyle(label)
    }
  }

  // Reset a label to default styles
  resetLabelStyle(label) {
    label.classList.remove('bg-gray-600', 'border-gray-500')
    label.classList.add('bg-card-background-alternative', 'border-gray-600')
  }

  // Update all input labels
  updateStyles() {
    this.inputTargets.forEach(input => {
      this.updateLabelStyle(input)
    })
  }
}
