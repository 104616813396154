import '@hotwired/turbo-rails';
import * as ActiveStorage from '@rails/activestorage';
import '../channels';
import 'trix';
import '@rails/actiontext';
import './application.scss';
import './application.css';
import '../inits';
import '../controllers';
import '../lib/yoroi-hack';
import Bugsnag from '@bugsnag/js';
import '@lottiefiles/lottie-player';
import 'swiper/css/bundle';
import { Magnet } from '@/web3/magnet/magnet';

if (!!import.meta.env.VITE_BUGSNAG_FRONTEND_API_KEY) {
  Bugsnag.start({
    collectUserIp: false, // we don't collect your personal data when it's not strictly necessary!
    apiKey: import.meta.env.VITE_BUGSNAG_FRONTEND_API_KEY,
    onError: function (event) {
      const userElement = document.querySelector('[data-current-user-id]');
      if (!!userElement) {
        event.setUser(userElement.dataset.currentUserId);
      }
    },
  });
}
ActiveStorage.start();
let getChainsConfig = function () {
  const configElement = document.querySelector('[data-magnet-chains]');
  var chains = [];
  if (configElement?.dataset.magnetChains) {
    chains = JSON.parse(configElement.dataset.magnetChains);
  }
  return chains;
};

['DOMContentLoaded'].forEach((event) => {
  document.addEventListener(event, () => {
    window.magnet = new Magnet({
      chainsConfig: getChainsConfig(),
      walletConnectProjectId: import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID,
      maxRoninReconnectAttempts:
        import.meta.env.VITE_MAGNET_MAX_RONIN_RECONNECT_ATTEMPTS || 30,
      roninReconnectDelayMs:
        import.meta.env.VITE_MAGNET_RONIN_RECONNECT_DELAY_MS || 5,
      errorLogger: {
        logError: (error, metadata) => {
          Bugsnag.notify(error, (event) => {
            event.addMetadata('magnet', metadata);
          });
        },
      },
    });
  });
});
