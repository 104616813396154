import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "submit"]

  connect() {
    // If there's no checkbox target (special requirement is not present),
    // we don't need to do anything
    if (!this.hasCheckboxTarget) return

    // Initial state - ensure submit is disabled if checkbox exists and is unchecked
    this.toggleSubmitButton()
  }

  toggleSubmitButton() {
    // Only proceed if we have both targets
    if (!this.hasCheckboxTarget || !this.hasSubmitTarget) return

    // Enable/disable submit button based on checkbox state
    this.submitTarget.disabled = !this.checkboxTarget.checked
  }

  // Checkbox change handler
  handleChange() {
    this.toggleSubmitButton()
  }
}
