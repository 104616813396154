import { Controller } from 'stimulus';
import { gsap } from 'gsap';

gsap.registerEffect({
  name: "counter",
  extendTimeline: true,
  defaults: {
    end: 0,
    duration: 1.2,
    ease: "power1",
    increment: 1,
    decimals: 2 // Add a default decimal value
  },
  effect: (targets, config) => {
    let tl = gsap.timeline();
    targets[0].innerText = targets[0].innerText.replace(/,/g, '');

    let initialValue = parseFloat(targets[0].innerText);
    let countInDecimals = !Number.isInteger(initialValue);

    tl.to(targets, {
      duration: config.duration,
      innerText: config.end,
      modifiers: {
        innerText: function (innerText) {
          let value = parseFloat(innerText);
          if (Number.isInteger(config.end) && !countInDecimals) {
            return Math.round(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          } else {
            return value.toFixed(config.decimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
        }
      },
      ease: config.ease
    }, 0);

    return tl;
  }
});

/**
 * @property {number} finishValue
 * @property {boolean} hasFinishValue
 * @property {boolean} hasTextTarget
 * @property {HTMLElement} counterTarget
 * @property {HTMLElement} textTarget
 */

export default class extends Controller {
  static targets = ['counter', 'text'];
  static values = { finish: Number };

  connect() {
    if (!this.hasFinishValue) {
      return console.error("You must provide a `data-count-up-finish-value` attribute.");
    }

    const tl = gsap.timeline();
    if (this.hasCounterTarget) {
      tl.fromTo(this.counterTarget, { opacity: 0 }, { opacity: 1, duration: 1.2 }, 0);
      tl.counter(this.counterTarget, { end: this.finishValue, duration: 1.2, decimals: 2 }, 0); // Pass the decimals config
    }

    if (this.hasTextTarget) {
      tl.fromTo(this.textTarget, { opacity: 0 }, { opacity: 1, duration: 1.2, delay: 1 }, 0);
    }
  }
}
