import { Controller } from 'stimulus';
import gsap from 'gsap';

export default class extends Controller {
  static targets = [
    'displayName',
    'roiExplainer',
  ];

  connect() {
    // Remove hidden class and measure height
    this.roiExplainerTarget.classList.remove('hidden');

    // Store initial states
    gsap.set(this.roiExplainerTarget, {
      height: 0,
      opacity: 0,
      overflow: 'hidden'
    });

    // Create a proxy object for the background animation
    this.backgroundProxy = { opacity: 0.03 };
    const element = this.element;

    // Get the natural height of the ROI explainer
    gsap.set(this.roiExplainerTarget, { opacity: 1, height: 'auto' });
    const roiHeight = this.roiExplainerTarget.offsetHeight;

    // Reset ROI explainer to initial state
    gsap.set(this.roiExplainerTarget, {
      height: 0,
      opacity: 0
    });

    // Create timeline for the animation
    this.timeline = gsap.timeline({ paused: true })
      .to(this.displayNameTarget, {
        duration: 0.2,
        height: 0,
        opacity: 0,
        ease: 'power2.inOut'
      })
      .to(this.roiExplainerTarget, {
        duration: 0.2,
        height: roiHeight,
        opacity: 1,
        ease: 'power2.inOut'
      }, '<')
      .to(this.backgroundProxy, {
        opacity: 0.12,
        duration: 0.2,
        ease: 'power2.out',
        onUpdate: function() {
          element.style.background = `
            radial-gradient(178.03% 131.33% at 100% 0%, 
              rgba(16, 25, 26, 0.00) 0%, 
              rgba(16, 25, 26, 0.80) 87.47%
            ),
            radial-gradient(
              64.2% 72.81% at 50% 0%, 
              rgba(219, 242, 255, ${this.backgroundProxy.opacity}) 0%, 
              rgba(250, 224, 236, ${this.backgroundProxy.opacity}) 100%
            ),
            #10191A
          `;
          }.bind(this)
        }, '<');
  }

  mouseEnter() {
    this.timeline.play();
  }

  mouseLeave() {
    this.timeline.reverse();
  }
}
