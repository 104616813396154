import { IPaymentIntent } from '../../model/payment_intent';
import { Compass } from '../../core/compass';
import { Payable } from '../../model/payable';
import { CompassError, ErrorCode } from '../../model/compass_error';
import { IWeb3Client } from '../../web3/web3_client';
import { TokenTxPaymentIntent } from '../../model/intents/token_tx_payment_intent';
import { DropletPaymentIntent } from '../../model/intents/droplet_payment_intent';
import { DeckPaymentIntent } from '../../model/intents/deck_payment_intent';
import { DeckClaimIntent } from '../../model/intents/deck_claim_intent';
import { ICompassConfirmViewInput } from './view_input';
import { ICompassConfirmViewOutput } from './view_output';
import { StatusType } from '../../model/status';
import { explorerUrl } from '../../util/chain_utils';
import { IAddressFormatter } from '../../model/address_formatter';
import { CompassAPI } from '../../core/compass_api';
import { ICompassStatusViewInput } from '../status/view_input';
import { Chain } from '../../model/chain';
import { ICompassStatusViewOutput } from '../status/view_output';

export interface ICompassConfirmRouter {
  goBackToCompassForm(paymentIntent: IPaymentIntent): void;
  dismissModal(): void;
  goToSuccess(): void;
}

type CompassConfirmPresenterParams = {
  compassBaseUrl: string;
  payable: Payable;
  paymentIntent: IPaymentIntent;
  paymentState: string;
  view: ICompassConfirmViewInput;
  statusView: ICompassStatusViewInput;
  router: ICompassConfirmRouter;
  addressFormatter: IAddressFormatter;
  expiresAt: Date;
};

export class CompassConfirmPresenter
  implements ICompassConfirmViewOutput, ICompassStatusViewOutput
{
  private readonly compassBaseUrl: string;
  private readonly paymentIntent: IPaymentIntent;
  private readonly expiresAt: Date;
  private paymentState: string;

  private addressIdentifier?: string;
  private selectedChain?: Chain;

  view: ICompassConfirmViewInput;
  statusView: ICompassStatusViewInput;
  router: ICompassConfirmRouter;
  addressFormatter: IAddressFormatter;
  compass?: CompassAPI;

  boundBeforeUnloadListener: (event: BeforeUnloadEvent) => void;

  constructor({
    compassBaseUrl,
    payable,
    paymentIntent,
    paymentState,
    view,
    statusView,
    expiresAt,
    router,
    addressFormatter,
  }: CompassConfirmPresenterParams) {
    this.compassBaseUrl = compassBaseUrl;
    this.paymentIntent = this.buildPaymentIntent(payable, paymentIntent);

    this.paymentIntent.payable = payable;
    this.paymentState = paymentState;

    this.view = view;
    this.statusView = statusView;
    this.router = router;
    this.expiresAt = expiresAt;

    this.addressFormatter = addressFormatter;

    this.boundBeforeUnloadListener = this.beforeUnloadListener.bind(this);
  }

  /*
   * IViewOutput implementation
   */
  async onWeb3Connect(web3Client: IWeb3Client): Promise<void> {
    this.compass = new Compass({
      web3Client: web3Client,
      compassBaseUrl: this.compassBaseUrl,
    });
    this.addressIdentifier = await this.compass.getAddressIdentifier();
    if (this.paymentState === 'processed') return;

    await this.updateStatusInView();
  }

  async onWeb3ChainChange(chain: Chain): Promise<void> {
    this.selectedChain = chain;
    if (this.paymentState === 'processed') return;
    if (this.compass) {
      this.addressIdentifier = await this.compass?.getAddressIdentifier();
    }

    await this.updateStatusInView();
  }

  async onActionButtonClick(): Promise<void> {
    if (this.paymentState === 'processed') return;
    if (!this.paymentIntent.preparedTransaction) return;

    this.paymentState = 'intent_sent';
    console.log('[COMPASS] Payment initiated', this.paymentIntent);
    this.view.disableActionButton();

    setTimeout(async () => {
      this.askForConfirmationBeforeUnload();
    }, 1000);

    let intervalId: NodeJS.Timeout | null = null;

    try {
      const payPromise = this.compass?.pay(
        {
          paymentIntent: this.paymentIntent,
        },
        {
          onWalletConfirmationRequested: async (paymentIntent) => {
            console.log(
              '[COMPASS] Wallet confirmation requested',
              paymentIntent
            );
            this.statusView.showStatus({
              title: 'Waiting for confirmation',
              statusType: StatusType.loading,
              message: 'Confirm this transaction in your wallet.',
            });
            this.view.disableActionButton();
            this.view.hideBackOrCancelButton();
          },
          onTransactionSubmitted: async (transactionHash: string) => {
            console.log('[COMPASS] Transaction submitted', transactionHash);
            this.paymentIntent.transactionHash = transactionHash;
            this.paymentState = 'confirmed_by_user';
            await this.updateStatusInView();
          },
          onPaymentIntentConfirmed: async (paymentIntent) => {
            console.log('[COMPASS] Payment intent confirmed');
            this.paymentState = 'intent_confirmed';
            await this.updateStatusInView();
          },
          onPaymentIntentConfirmationError: async (paymentIntent, error) => {},
          onTransactionReplaced: async (data: any) => {
            console.log(
              `[COMPASS] Transaction replaced by ${data.transaction.hash}`
            );
            this.paymentIntent.transactionHash = data.transaction.hash;
            this.statusView.showStatus({
              title: 'Transaction sent to the blockchain (replaced)',
              statusType: StatusType.loading,
              message: 'Waiting for confirmation...',
              linkUrl: explorerUrl(
                this.paymentIntent.paymentMethod.token.chain,
                this.paymentIntent.transactionHash!
              ),
              linkText: 'View TX',
            });
          },
          onTransactionCancelled: async (paymentIntent: IPaymentIntent) => {
            console.log('[COMPASS] Transaction cancelled');
            this.paymentState = 'failed';
            await this.updateStatusInView('The transaction was cancelled.');
          },
        }
      );

      // Fallback polling in case the approval callback is not received
      const pollStatusPromise = new Promise(async (resolve) => {
        intervalId = setInterval(async () => {
          // TODO: Shouldn't be here. UI update should be done via ViewInput
          let showPanel = document.getElementById(
            'compass_payment_intent_panel'
          ) as HTMLIFrameElement;
          const response = await fetch(showPanel.dataset.statusUrl!, {
            headers: {
              Accept: 'application/json',
            },
          });
          const data = await response.json();
          if (data.status === 'processed' || data.status === 'failed') {
            showPanel.src = showPanel.dataset.showUrl!;

            clearInterval(intervalId!); // Stop the polling
            intervalId = null;
            resolve('Payment successful'); // Resolve to break out of Promise.race
          }
        }, 5000); // Poll every 5 seconds
      });

      await Promise.race([payPromise, pollStatusPromise]);

      // The transaction was confirmed by the blockchain, but the backend still needs to verify and accept it.
      this.stopAskingForConfirmationBeforeUnload();
    } catch (error) {
      if (intervalId) {
        clearInterval(intervalId);
        intervalId = null;
      }
      this.stopAskingForConfirmationBeforeUnload();
      console.log('[COMPASS] Error', error);
      if (error instanceof CompassError) {
        switch (error.code) {
          case ErrorCode.BackendApiError:
            // The backend will update the status via Turbo Streams
            return;
          case ErrorCode.UserRejectedTransactionError:
            this.showRetriableStatus(
              'Transaction rejected by the user',
              'The transaction was rejected in the wallet. Click below to try again.',
              StatusType.warning
            );
            return;
          case ErrorCode.WalletNotConnectedError:
            this.showRetriableStatus(
              'Wallet not connected',
              'Wallet disconnected. Please connect your wallet and try again.'
            );
            return;
          case ErrorCode.FromAddressMismatchError:
            this.paymentState = 'wrong_wallet_connected'; // Should not be reachable, since Compass should capture it first
            break;
          case ErrorCode.InsufficientBalanceError:
            this.showRetriableStatus(
              'Insufficient balance',
              `Please make sure you have at least ${this.paymentIntent.amount} ${this.paymentIntent.paymentMethod.token.ticker} before continuing.`
            );
            return;
          case ErrorCode.SmartContractNotWhitelistedError:
            this.showRetriableStatus(
              'Transaction failed: insufficient balance',
              'The transaction failed due to insufficient balance. Please make sure you have enough balance to cover the transaction fee. If you are distributing a token, make sure the Spring Smart Contract is whitelisted.'
            );
            return;
          case ErrorCode.InvalidNonceError:
            this.showRetriableStatus(
              'Transaction failed: invalid nonce',
              'The transaction failed because the nonce is invalid. This means that another transaction was made from the same wallet at the same time, or the nonce was manually edited in the wallet app. Please try again.'
            );
            return;
          case ErrorCode.BlindSigningDisabledError:
            this.showRetriableStatus(
              'Transaction failed: blind signing disabled',
              'Please enable Blind signing or Contract data in your wallet.'
            );
            return;
          case ErrorCode.UnkownRPCNoKeyringError:
            this.showRetriableStatus(
              'Transaction failed: no keyring found',
              'Your wallet encountered a problem while signing the transaction. Please lock and unlock it and try again.'
            );
            return;
          case ErrorCode.UnkownRPCTransactionUnderpricedError:
            this.showRetriableStatus(
              'Transaction failed: transaction underpriced',
              'The gas price set for the transaction is too low. Please check that there is no other transaction waiting for confirmation. Otherwise, try again.'
            );
            return;
          case ErrorCode.UnkownRPCError:
            this.showRetriableStatus(
              'Transaction fail',
              'Your wallet encountered a problem while sending the transaction to the blockchain. Please try again, or use a different wallet app if the problem persists.'
            );
            return;
          case ErrorCode.UnknownLedgerError:
            this.showRetriableStatus(
              'Transaction failed: Ledger error',
              'Could not connect to your Ledger. Please make sure the device is connected and unlocked and try again.'
            );
            return;
          case ErrorCode.LedgerDeviceInvalidDataReceivedError:
            this.showRetriableStatus(
              'Transaction failed: Ledger error',
              'This is often caused by outdated firmware or having other programs running on the Ledger at the same time. Please make sure the Ledger firmware is up-to-date, close those other programs and try again. If the problem persists, try resetting your ledger device.'
            );
            return;
          case ErrorCode.MetamaskHavingTroubleConnectingError:
            this.showRetriableStatus(
              'Transaction failed: Metamask cannot connect to the blockchain',
              'MetaMask is having trouble connecting to the network. Please try again.'
            );
            return;
        }
        await this.updateStatusInView();
      } else {
        this.paymentState = 'failed';
        await this.updateStatusInView();
      }
    }
  }

  onBackButtonClick(): void {
    // Intentionally not awaiting the discardPaymentIntent call
    this.compass?.discardPaymentIntent(this.paymentIntent);
    this.router.goBackToCompassForm(this.paymentIntent);
  }

  onCancelButtonClick(): void {
    this.compass?.discardPaymentIntent(this.paymentIntent);
    this.router.dismissModal();
  }

  onCloseButtonClick(): void {
    this.router.goToSuccess();
  }

  /*
   * IStatusViewOutput implementation
   */
  onCompassStatusViewInputUpdated(statusView: ICompassStatusViewInput): void {
    this.statusView = statusView;
  }

  private async updateStatusInView(message?: string): Promise<void> {
    if (!this.compass) return;

    if (this.paymentIntent.guid)
      this.view.showPaymentIntentGUID(this.paymentIntent.guid);

    // First check some edge cases
    if (!this.isWalletConnected()) {
      this.statusView.showStatus({
        title: 'Wallet not connected',
        statusType: StatusType.error,
        message:
          'Wallet disconnected. Please connect your wallet and try again.',
      });
      this.view.disableActionButton();
      this.view.showBackOrCancelButton();
      return;
    }
    if (
      this.isWalletConnectedToCorrectAddress() === false ||
      this.paymentState == 'wrong_wallet_connected'
    ) {
      this.statusView.showStatus({
        title: 'Wrong wallet connected',
        statusType: StatusType.error,
        message: `The connected wallet (${this.addressFormatter(
          this.addressIdentifier!
        )}) is not the one set for this payment (${this.addressFormatter(
          this.paymentIntent.paymentMethod.addressIdentifier
        )}). Please connect to the original wallet, or press \'Back\' and try again using your current wallet.`,
      });
      this.view.disableActionButton();
      this.view.showBackOrCancelButton();
      return;
    } else if (!this.isWalletConnectedToCorrectChain()) {
      this.statusView.showStatus({
        title: 'Wallet connected to the wrong network',
        statusType: StatusType.error,
        message:
          'Your wallet is connected to the wrong network. Either switch to the network set for this payment in your wallet or go back and reconfigure the payment for the current chain.',
      });
      this.view.disableActionButton();
      this.view.showBackOrCancelButton();
      return;
    }
    switch (this.paymentState) {
      case 'intent_sent':
        this.statusView.showStatus({
          title: 'Waiting for confirmation',
          statusType: StatusType.loading,
          message: 'Confirm this transaction in your wallet.',
        });
        this.view.enableActionButton();
        this.view.showBackOrCancelButton();
        break;
      case 'intent_made':
        this.statusView.hideStatus();
        this.view.enableActionButton();
        this.view.showBackOrCancelButton();
        break;
      case 'confirmed_by_user':
      case 'intent_confirmed':
      case 'verified':
      case 'accepted':
      case 'notified':
        this.statusView.showStatus({
          title: 'Transaction sent to the blockchain',
          statusType: StatusType.loading,
          message: 'Waiting for confirmation...',
          linkUrl: this.paymentIntent.transactionHash
            ? explorerUrl(
                this.paymentIntent.paymentMethod.token.chain,
                this.paymentIntent.transactionHash!
              )
            : undefined,
          linkText: this.paymentIntent.transactionHash ? 'View TX' : undefined,
        });
        this.view.disableActionButton();
        this.view.hideBackOrCancelButton();
        break;
      case 'processed':
        this.statusView.showStatus({
          title: 'Payment completed',
          statusType: StatusType.success,
          message: 'The payment is completed.',
          linkUrl: this.paymentIntent.transactionHash
            ? explorerUrl(
                this.paymentIntent.paymentMethod.token.chain,
                this.paymentIntent.transactionHash!
              )
            : undefined,
          linkText: this.paymentIntent.transactionHash ? 'View TX' : undefined,
        });
        this.view.disableActionButton();
        this.view.hideBackOrCancelButton();
        break;
      case 'failed':
      case 'unconfirmed_failed':
        this.statusView.showStatus({
          title: 'Payment failed',
          statusType: StatusType.error,
          message:
            message ||
            'There was en error processing the payment. If the transaction succeeded in your wallet, just wait a few ' +
              'seconds and we will process it automatically. Please contact support otherwise.',
          linkUrl: this.paymentIntent.transactionHash
            ? explorerUrl(
                this.paymentIntent.paymentMethod.token.chain,
                this.paymentIntent.transactionHash!
              )
            : undefined,
          linkText: this.paymentIntent.transactionHash ? 'View TX' : undefined,
        });
        this.view.disableActionButton();
        this.view.showBackOrCancelButton();
        break;
    }
  }

  private showRetriableStatus(
    title: string,
    message: string,
    statusType: StatusType = StatusType.error
  ): void {
    this.statusView.showStatus({
      title: title,
      statusType: statusType,
      message: message,
      linkUrl: this.paymentIntent.transactionHash
        ? explorerUrl(
            this.paymentIntent.paymentMethod.token.chain,
            this.paymentIntent.transactionHash!
          )
        : undefined,
      linkText: this.paymentIntent.transactionHash ? 'View TX' : undefined,
    });
    this.view.enableActionButton();
    this.view.showBackOrCancelButton();
  }

  private buildPaymentIntent(
    payable: Payable,
    paymentIntent: IPaymentIntent
  ): IPaymentIntent {
    if (payable.acceptedPaymentMethod === 'droplet') {
      return new DropletPaymentIntent({
        guid: paymentIntent.guid,
        payable: payable,
        paymentMethod: paymentIntent.paymentMethod,
        preparedTransaction: {
          transaction: paymentIntent.preparedTransaction,
        },
      });
    } else if (payable.acceptedPaymentMethod === 'deck') {
      return new DeckPaymentIntent({
        guid: paymentIntent.guid,
        payable: payable,
        paymentMethod: paymentIntent.paymentMethod,
        preparedTransaction: {
          transaction: paymentIntent.preparedTransaction,
        },
      });
    } else if (payable.acceptedPaymentMethod === 'claim') {
      return new DeckClaimIntent({
        guid: paymentIntent.guid,
        payable: payable,
        paymentMethod: paymentIntent.paymentMethod,
        preparedTransaction: {
          transaction: paymentIntent.preparedTransaction,
        },
      });
    } else {
      return new TokenTxPaymentIntent({
        amount: payable.amount,
        guid: paymentIntent.guid,
        payable: payable,
        paymentMethod: paymentIntent.paymentMethod,
        preparedTransaction: {
          transaction: paymentIntent.preparedTransaction,
        },
      });
    }
  }

  // Wallet state checks
  private isWalletConnected(): boolean {
    return this.selectedChain !== undefined;
  }

  private isWalletConnectedToCorrectAddress(): boolean | undefined {
    if (!this.addressIdentifier) return undefined;

    return (
      this.addressIdentifier ==
      this.paymentIntent.paymentMethod.addressIdentifier.toLowerCase()
    );
  }

  private isWalletConnectedToCorrectChain(): boolean {
    return (
      this.paymentIntent.paymentMethod.token.chain.id == this.selectedChain?.id
    );
  }

  private beforeUnloadListener(event: BeforeUnloadEvent): void {
    event.preventDefault();
    event.returnValue = '';
  }
  private askForConfirmationBeforeUnload(): void {
    window.addEventListener('beforeunload', this.boundBeforeUnloadListener);
  }

  private stopAskingForConfirmationBeforeUnload(): void {
    window.removeEventListener('beforeunload', this.boundBeforeUnloadListener);
  }
}
