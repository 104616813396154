import { Controller } from "stimulus";
import gsap from "gsap";

export default class extends Controller {
  static targets = ["content"];
  static values = {
    ellipsis: { type: Boolean, default: false },
    hoisted: { type: Boolean, default: false },
    hoistedId: { type: String, default: "" },
    propagateClick: { type: Boolean, default: true }
  }

  connect() {
    this.handleClickOutside = this.handleClickOutside.bind(this);
    document.addEventListener('click', this.handleClickOutside);
    this._currentTooltip = this.contentTarget;
    this._hideTimeout = null;
    this._isMouseOverContent = false;
    this._isVisible = false;

    // Add click handler to the trigger element
    this.element.addEventListener('click', this.handleTriggerClick.bind(this));
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickOutside);
    if (this._clonedTooltip) {
      this._clonedTooltip.remove();
    }
    if (this._hideTimeout) {
      clearTimeout(this._hideTimeout);
    }
  }

  // Handle click on trigger element
  handleTriggerClick(event) {
    if (!this.propagateClickValue) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (this._isVisible) {
      this.hide();
    } else {
      this.show();
    }
  }

  show(event) {
    // If it's a mouseenter event and we're on a touch device, ignore it
    if (event && event.type === 'mouseenter' && this.isTouchDevice()) {
      return;
    }

    if (this._hideTimeout) {
      clearTimeout(this._hideTimeout);
      this._hideTimeout = null;
    }

    if (this.ellipsisValue && !this.isEllipsisActive(this.element.firstElementChild)) {
      return;
    }

    // If already visible, just ensure proper state
    if (this._isVisible) {
      return;
    }

    if (this.hoistedValue) {
      this._cloneTooltip();
    } else {
      this._currentTooltip = this.contentTarget;
    }

    this.setPosition();
    this._currentTooltip.classList.remove('hidden');
    this.setupAnimation();
    this.animation.play();
    this._isVisible = true;

    // Only set up hover listeners if not on a touch device
    if (!this.isTouchDevice() && !this._currentTooltip._hasListeners) {
      this._currentTooltip.addEventListener('mouseenter', () => {
        this._isMouseOverContent = true;
        this.clearHideTimeout();
      });

      this._currentTooltip.addEventListener('mouseleave', () => {
        this._isMouseOverContent = false;
        this.startHideTimeout();
      });

      this._currentTooltip._hasListeners = true;
    }
  }

  hide(event) {
    // For mouse events, only proceed with hide if we're not on a touch device
    if (event && event.type === 'mouseleave' && this.isTouchDevice()) {
      return;
    }

    // If we're moving to the content on desktop, don't start the hide timeout
    if (!this.isTouchDevice() && event && event.relatedTarget &&
      (this._currentTooltip.contains(event.relatedTarget) || this._isMouseOverContent)) {
      return;
    }

    this.startHideTimeout();
  }

  clearHideTimeout() {
    if (this._hideTimeout) {
      clearTimeout(this._hideTimeout);
      this._hideTimeout = null;
    }
  }

  startHideTimeout() {
    this.clearHideTimeout();
    this._hideTimeout = setTimeout(() => {
      // Only hide if mouse is not over content (for desktop)
      if (this.isTouchDevice() || !this._isMouseOverContent) {
        this.hideTooltip();
      }
    }, 200);
  }

  hideTooltip() {
    if (this.animation) {
      this.animation.reverse();
      this.animation.eventCallback("onReverseComplete", () => {
        this._currentTooltip.classList.add('hidden');
        if (this.hoistedValue && this._clonedTooltip) {
          this._clonedTooltip.remove();
          this._clonedTooltip = null;
          this._currentTooltip = this.contentTarget;
        }
        this._isVisible = false;
      });
    }
  }

  handleClickOutside(event) {
    // Don't handle click outside if the click was on the trigger element
    if (this.element.contains(event.target)) {
      return;
    }

    // Hide if click was outside both trigger and tooltip content
    if (!this._currentTooltip.contains(event.target) && this._isVisible) {
      this.hideTooltip();
    }
  }

  // Helper method to detect touch device
  isTouchDevice() {
    return ('ontouchstart' in window) ||
      (navigator.maxTouchPoints > 0) ||
      (navigator.msMaxTouchPoints > 0);
  }

  setPosition() {
    let position = this.element.dataset.tailwindTooltipPosition;
    if (!position) position = 'above';

    this._currentTooltip.classList.remove('top-full', 'bottom-full', 'mt-2', 'mb-2', 'left-1/2', '-translate-x-1/2', 'right-full', 'mr-2', 'left-full', 'ml-2', '-translate-y-1/2', 'top-1/2');

    switch (position) {
      case 'above':
        this._currentTooltip.classList.add('bottom-full', 'mb-2', 'left-1/2', '-translate-x-1/2');
        break;
      case 'below':
        this._currentTooltip.classList.add('top-full', 'mt-2', 'left-1/2', '-translate-x-1/2');
        break;
      case 'left':
        this._currentTooltip.classList.add('right-full', 'mr-2', 'top-1/2', '-translate-y-1/2');
        break;
      case 'right':
        this._currentTooltip.classList.add('left-full', 'ml-2', 'top-1/2', '-translate-y-1/2');
        break;
    }
  }

  setupAnimation() {
    let position = this.element.dataset.tailwindTooltipPosition;
    let fromVars, toVars;

    switch (position) {
      case 'above':
        fromVars = { opacity: 0, y: -10 };
        toVars = { opacity: 1, y: 0 };
        break;
      case 'below':
        fromVars = { opacity: 0, y: 10 };
        toVars = { opacity: 1, y: 0 };
        break;
      case 'left':
        fromVars = { opacity: 0, x: -10 };
        toVars = { opacity: 1, x: 0 };
        break;
      case 'right':
        fromVars = { opacity: 0, x: 10 };
        toVars = { opacity: 1, x: 0 };
        break;
      default:
        fromVars = { opacity: 0, y: -10 };
        toVars = { opacity: 1, y: 0 };
        break;
    }

    this.animation = gsap.timeline({ paused: true })
      .fromTo(this._currentTooltip, fromVars, { ...toVars, duration: 0.3, ease: "power1.out" });
  }

  isEllipsisActive(e) {
    return (e.offsetWidth < e.scrollWidth);
  }

  _cloneTooltip() {
    this._clonedTooltip = this.contentTarget.cloneNode(true);
    let hoistTarget = document.getElementById(this.hoistedIdValue) || document.body;
    hoistTarget.appendChild(this._clonedTooltip);
    this._setTooltipPosition(this._clonedTooltip);
    this._currentTooltip = this._clonedTooltip;
  }

  _setTooltipPosition(tooltip) {
    const rect = this.element.getBoundingClientRect();
    const contentRect = tooltip.getBoundingClientRect();

    tooltip.style.position = 'absolute';

    switch (this.element.dataset.tailwindTooltipPosition) {
      case 'above':
        tooltip.style.top = `${rect.top - contentRect.height}px`;
        tooltip.style.left = `${rect.left + rect.width / 2 - contentRect.width / 2}px`;
        break;
      case 'below':
        tooltip.style.top = `${rect.bottom}px`;
        tooltip.style.left = `${rect.left + rect.width / 2 - contentRect.width / 2}px`;
        break;
      case 'left':
        tooltip.style.top = `${rect.top + rect.height / 2 - contentRect.height / 2}px`;
        tooltip.style.left = `${rect.left - contentRect.width}px`;
        break;
      case 'right':
        tooltip.style.top = `${rect.top + rect.height / 2 - contentRect.height / 2}px`;
        tooltip.style.left = `${rect.right}px`;
        break;
      default:
        tooltip.style.top = `${rect.top - contentRect.height}px`;
        tooltip.style.left = `${rect.left + rect.width / 2 - contentRect.width / 2}px`;
        break;
    }
  }
}
