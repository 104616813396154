import { Controller } from 'stimulus';
import gsap from 'gsap';

export default class extends Controller {
  static targets = ['previous', 'next', 'turboFrame'];

  connect() {
    this.currentPage = parseInt(this.turboFrameTarget.firstElementChild?.dataset.pageNumber) || 1;
    this.isAnimating = false;

    // Ensure we remove any existing listener before adding a new one
    this.boundHandleTurboStream = this.handleTurboStream.bind(this);
    document.removeEventListener('turbo:before-stream-render', this.boundHandleTurboStream);
    document.addEventListener('turbo:before-stream-render', this.boundHandleTurboStream);

    // Wait for the next tick to ensure DOM is ready
    setTimeout(() => this.updateButtonStates(), 0);
  }

  disconnect() {
    document.removeEventListener('turbo:before-stream-render', this.boundHandleTurboStream);
  }

  handleTurboStream(event) {
    const streamElement = event.target;
    if (streamElement.action === 'replace' && streamElement.target === 'discover_show_cards') {
      event.preventDefault();

      const template = streamElement.querySelector('template');
      if (!template) {
        console.error('No template found in stream');
        return;
      }

      const newContent = template.content.firstElementChild;
      const currentContent = this.turboFrameTarget.firstElementChild;

      // Safety check to ensure we have both old and new content
      if (!newContent || !currentContent) {
        console.error('Missing content elements', { newContent, currentContent });
        Turbo.renderStreamMessage(event.target.outerHTML); // Fallback to default rendering
        return;
      }

      this.animateContent(currentContent, newContent);
    }
  }

  async goToPreviousPage() {
    if (this.isAnimating || this.currentPage <= 1) return;
    this.currentPage--;
    this.disableAllButtons();
    await this.fetchPage();
  }

  async goToNextPage() {
    if (this.isAnimating || !this.hasNextPage()) return;
    this.currentPage++;
    this.disableAllButtons();
    await this.fetchPage();
  }

  async fetchPage() {
    if (this.isAnimating) return;

    try {
      this.isAnimating = true;

      const response = await fetch(`${window.location.pathname}?page=${this.currentPage}`, {
        headers: {
          Accept: "text/vnd.turbo-stream.html",
          "X-Requested-With": "XMLHttpRequest"
        }
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const html = await response.text();

      // Safety check to ensure controller is still connected
      if (!this.turboFrameTarget) {
        console.error('TurboFrame target no longer exists');
        return;
      }

      Turbo.renderStreamMessage(html);
    } catch (error) {
      console.error('Error fetching page:', error);
      this.isAnimating = false;
      this.updateButtonStates();
    }
  }

  disableAllButtons() {
    if (this.hasPreviousTarget) this.previousTarget.disabled = true;
    if (this.hasNextTarget) this.nextTarget.disabled = true;
  }

  animateContent(oldContent, newContent) {
    // Safety check to ensure turboFrame still exists
    if (!this.turboFrameTarget) {
      console.error('TurboFrame target no longer exists');
      return;
    }

    const container = this.turboFrameTarget;

    // Safety check for container
    if (!container) {
      console.error('Container not found');
      return;
    }

    // Reset any existing styles
    gsap.set(oldContent, { clearProps: 'all' });

    // Setup initial states
    gsap.set(newContent, {
      opacity: 0,
    });

    try {
      // Add new content to DOM
      container.appendChild(newContent);

      // Create animation timeline
      const tl = gsap.timeline({
        onComplete: () => {
          if (oldContent && oldContent.parentNode) {
            oldContent.remove();
          }
          if (newContent) {
            gsap.set(newContent, {
              clearProps: 'all'
            });
          }
          this.isAnimating = false;
          this.updateButtonStates();
        }
      });

      // Simple fade out/fade in
      tl.to(oldContent, {
        opacity: 0,
        duration: 0.2,
        ease: 'power2.inOut'
      });

      tl.to(newContent, {
        opacity: 1,
        duration: 0.2,
        ease: 'power2.inOut'
      });

      return tl;
    } catch (error) {
      console.error('Animation error:', error);
      this.isAnimating = false;
      this.updateButtonStates();
    }
  }

  hasNextPage() {
    const currentContent = this.turboFrameTarget?.firstElementChild;
    return currentContent?.dataset.hasNextPage === 'true';
  }

  updateButtonStates() {
    if (!this.hasPreviousTarget || !this.hasNextTarget) return;

    this.previousTarget.disabled = this.currentPage <= 1;
    this.nextTarget.disabled = !this.hasNextPage();
  }
}
