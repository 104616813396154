import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    // Handle action-text-attachment elements
    this.element.querySelectorAll('action-text-attachment[content-type="image"]').forEach(this.processYoutubeElement);

    // Handle figure elements with data-trix-attachment
    this.element.querySelectorAll('figure[data-trix-attachment]').forEach(this.processYoutubeElement);
  }

  processYoutubeElement = (element) => {
    // Get the img element regardless of parent type
    const img = element.querySelector('img');
    if (!img) return;

    const imgUrl = img.src;
    if (!imgUrl.includes('youtube.com/vi/')) return;

    // Extract the video ID from the URL
    const videoID = imgUrl.split('/vi/')[1].split('/')[0];

    // Create a wrapper div for responsiveness
    const divWrapper = document.createElement('div');
    divWrapper.className = 'responsive-video-wrapper';

    // Create an iframe element
    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', `https://www.youtube.com/embed/${videoID}`);
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
    iframe.setAttribute('allowfullscreen', 'true');

    // Append iframe to the div wrapper
    divWrapper.appendChild(iframe);

    // Find the element to replace (figure or action-text-attachment)
    const targetElement = element.tagName.toLowerCase() === 'figure' ? element : element.querySelector('figure');
    targetElement.parentNode.replaceChild(divWrapper, targetElement);
  }
}
