import { Controller } from "@hotwired/stimulus"
import { gsap } from "gsap"

export default class extends Controller {
  static targets = ["container", "leftArrow", "rightArrow"]

  connect() {
    this.scrollableContainer = this.containerTarget.querySelector('.scrollbar-hide')

    this.boundUpdateArrows = this.updateArrows.bind(this)
    this.debouncedUpdateArrows = this.debounce(this.updateArrows.bind(this), 250)

    this.updateArrows()
    this.scrollableContainer.addEventListener("scroll", this.boundUpdateArrows)
    window.addEventListener("resize", this.debouncedUpdateArrows)

    // Initialize arrows as fully transparent but not hidden
    gsap.set([this.leftArrowTarget, this.rightArrowTarget], { autoAlpha: 0, display: 'flex' })
  }

  disconnect() {
    this.scrollableContainer.removeEventListener("scroll", this.boundUpdateArrows)
    window.removeEventListener("resize", this.debouncedUpdateArrows)
  }

  updateArrows() {
    const { scrollLeft, scrollWidth, clientWidth } = this.scrollableContainer

    this.animateArrow(this.leftArrowTarget, scrollLeft > 0)
    this.animateArrow(this.rightArrowTarget, scrollLeft + clientWidth < scrollWidth)
  }

  animateArrow(arrow, show) {
    gsap.to(arrow, {
      autoAlpha: show ? 1 : 0,
      duration: 0.3,
      ease: "power2.inOut",
      display: show ? 'flex' : 'none'
    })
  }

  scrollLeft(e) {
    e.preventDefault()
    e.stopPropagation()
    this.scroll(-300)
  }

  scrollRight(e) {
    e.preventDefault()
    e.stopPropagation()
    this.scroll(300)
  }

  scroll(amount) {
    gsap.to(this.scrollableContainer, {
      scrollLeft: `+=${amount}`,
      duration: 0.5,
      ease: "power2.out"
    })
  }

  debounce(func, wait) {
    let timeout
    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => func.apply(this, args), wait)
    }
  }
}
