import { Controller } from "@hotwired/stimulus"
import gsap from "gsap"

export default class extends Controller {
  static values = {
    scrollThreshold: { type: Number, default: 10 }
  }

  connect() {
    // Set initial state
    this.isExpanded = true

    // Create GSAP timeline for animations
    this.timeline = gsap.timeline({ paused: true })
    this.setupAnimation()

    // Bind methods
    this.scrollHandler = this.handleScroll.bind(this)
    this.resetState = this.resetState.bind(this)

    // Check initial scroll position and set correct state
    this.checkScrollPosition()

    // Add scroll listener
    window.addEventListener('scroll', this.scrollHandler, { passive: true })

  }

  disconnect() {
    window.removeEventListener('scroll', this.scrollHandler)

    // Clean up GSAP timeline
    if (this.timeline) {
      this.timeline.kill()
    }
  }

  resetState() {
    if (document.body.getAttribute('data-turbo-action') === 'advance') {
      return
    }
    // Reset the animation state
    if (this.timeline) {
      this.timeline.kill()
      this.timeline = gsap.timeline({ paused: true })
      this.setupAnimation()
    }

    this.isExpanded = true

    // Reset the element's styles
    gsap.set(this.element, {
      padding: '1rem'
    })

    // Check scroll position after a brief delay to ensure DOM is ready
    setTimeout(() => this.checkScrollPosition(), 0)
  }

  checkScrollPosition() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    if (scrollTop > this.scrollThresholdValue) {
      this.timeline.progress(1)
      this.isExpanded = false
    } else {
      this.timeline.progress(0)
      this.isExpanded = true
    }
  }

  setupAnimation() {
    this.timeline.to(this.element, {
      padding: 0,
      duration: 0.3,
      ease: "power2.out"
    })
  }

  handleScroll() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop

    if (scrollTop > this.scrollThresholdValue && this.isExpanded) {
      // Remove outer padding
      this.timeline.play()
      this.isExpanded = false
    } else if (scrollTop <= this.scrollThresholdValue && !this.isExpanded) {
      // Restore outer padding
      this.timeline.reverse()
      this.isExpanded = true
    }
  }
}
