import { Controller } from '@hotwired/stimulus';
import gsap from 'gsap';

export default class extends Controller {
  static targets = [
    'container',
    'input',
    'closeButton',
    'searchFrame',
    'turboFrame',
    'spinnerTemplate',
    'pagination',
    'paginationTurbo'
  ];

  // Debounce timeout handler
  searchTimeout = null;
  isLoading = false;
  observer = null;

  connect() {
    document.addEventListener(
      'turbo:before-stream-render',
      this.handleTurboStream.bind(this)
    );

    // Initialize intersection observer for infinite scroll
    this.setupInfiniteScroll();
  }

  disconnect() {
    document.removeEventListener(
      'turbo:before-stream-render',
      this.handleTurboStream.bind(this)
    );

    if (this.observer) {
      this.observer.disconnect();
    }
  }

  setupInfiniteScroll() {
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !this.isLoading) {
            this.loadNextPage();
          }
        });
      },
      {
        root: null,
        rootMargin: '100px',
        threshold: 0.1,
      }
    );

    // Observe pagination target if it exists
    if (this.hasPaginationTarget) {
      this.observer.observe(this.paginationTarget);
    }
  }

  async loadNextPage() {
    if (this.isLoading) return;

    this.isLoading = true;

    const currentPage = parseInt(this.turboFrameTarget.dataset.currentPage) || 1;
    const url = new URL(window.location.href);
    url.searchParams.set('page', currentPage + 1);

    if (this.inputTarget.value.length >= 3) {
      url.searchParams.set('search_term', this.inputTarget.value);
    }

    try {
      const response = await fetch(url.toString(), {
        headers: {
          Accept: 'text/vnd.turbo-stream.html',
        },
      });

      const html = await response.text();
      Turbo.renderStreamMessage(html);

      // Update current page
      this.turboFrameTarget.dataset.currentPage = currentPage + 1;
    } catch (error) {
      console.error('Error:', error);
    } finally {
      this.isLoading = false;
    }
  }

  handleTurboStream(event) {
    const action = event.target.getAttribute('action');
    const elementId = event.target.getAttribute('target');

    if (action === 'replace' && elementId === 'discover_platform_cards' || elementId === 'discover_platform_table') {
      event.preventDefault();

      const template = event.target.firstElementChild;
      const newContent = template.content.firstElementChild;
      const shouldAnimate = newContent.dataset.animate !== 'false';

      if (!shouldAnimate) {
        const newNode = newContent.cloneNode(true);
        this.turboFrameTarget.replaceWith(newNode);

        if (this.hasPaginationTarget) {
          this.observer.observe(this.paginationTarget);
        }
        return
      }

      gsap.set(newContent, { opacity: 0 });

      gsap.to(this.turboFrameTarget, {
        opacity: 0,
        duration: 0.2,
        onComplete: () => {
          // Replace the entire turboFrameTarget with newContent
          const newNode = newContent.cloneNode(true);
          this.turboFrameTarget.replaceWith(newNode);

          // Animate the new node directly
          gsap.to(newNode, {
            opacity: 1,
            duration: 0.2,
            onComplete: () => {
              // Re-observe new pagination target if it exists
              if (this.hasPaginationTarget) {
                this.observer.observe(this.paginationTarget);
              }
            }
          });
        },
      });
    } else if (action === 'append' && elementId === 'discover_platform_cards_pagination' || elementId === 'discover_table_rows') {
      event.preventDefault();

      const template = event.target.firstElementChild;
      const newContent = template.content;

      // Remove existing pagination element before adding new content
      if (this.hasPaginationTarget) {
        const existingPagination = this.paginationTarget;
        if (existingPagination) {
          this.observer.unobserve(existingPagination);
          existingPagination.remove();
        }
      }

      // Get all elements except the last one (pagination)
      const newItems = Array.from(newContent.children).slice(0, -1);
      const newPagination = newContent.children[newContent.children.length - 1];

      // Append and animate new items
      newItems.forEach((item) => {
        gsap.set(item, { opacity: 0, y: 20 });
        this.paginationTurboTarget.appendChild(item.cloneNode(true));
      });

      // Append new pagination if it exists
      if (newPagination) {
        this.paginationTurboTarget.appendChild(newPagination.cloneNode(true));
        // Observe new pagination target
        if (this.hasPaginationTarget) {
          this.observer.observe(this.paginationTarget);
        }
      }

      // Animate new items
      gsap.to(newItems.map((_, i) => this.paginationTurboTarget.children[this.paginationTurboTarget.children.length - newItems.length - (newPagination ? 1 : 0) + i]), {
        opacity: 1,
        y: 0,
        duration: 0.4,
        stagger: 0.1,
        ease: 'power2.out',
      });
    }
  }

  // Handle container click to focus input
  containerClick() {
    this.inputTarget.focus();
  }

  // Handle input changes
  inputChange() {
    const value = this.inputTarget.value;

    // Handle close button visibility
    if (value.length > 0) {
      this.showCloseButton();
    } else {
      this.hideCloseButton();
    }

    // Clear existing timeout if user is still typing
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    // Only trigger search if 3 or more characters
    if (value.length >= 3) {
      // Wait for 300ms of no typing before searching
      this.searchTimeout = setTimeout(() => {
        this.performSearch(value);
      }, 300);
    } else if (value.length === 0) {
      // If input is cleared, restore initial state
      this.performSearch('');
    }
  }

  async performSearch(searchTerm) {
    // Fade out current content
    await gsap
      .to(this.turboFrameTarget.firstElementChild, {
        opacity: 0,
        duration: 0.3,
      })
      .then(() => {
        // Clear current content
        this.turboFrameTarget.innerHTML = '';

        // Add and fade in spinner
        const spinnerContent =
          this.spinnerTemplateTarget.content.cloneNode(true);
        this.turboFrameTarget.appendChild(spinnerContent);
        gsap.fromTo(
          this.turboFrameTarget.firstElementChild,
          { opacity: 0 },
          { opacity: 1, duration: 0.3 }
        );
      });

    const url = new URL(window.location.href);
    url.searchParams.set('search_term', searchTerm);

    try {
      const response = await fetch(url.toString(), {
        headers: {
          Accept: 'text/vnd.turbo-stream.html',
        },
      });

      const html = await response.text();
      Turbo.renderStreamMessage(html); // This will trigger the turbo:before-stream-render event
    } catch (error) {
      console.error('Error:', error);
    }
  }

  // Show close button with fade animation
  showCloseButton() {
    const button = this.closeButtonTarget;
    if (button.classList.contains('hidden')) {
      button.classList.remove('hidden');
      gsap.fromTo(button, { opacity: 0 }, { opacity: 1, duration: 0.3 });
    }
  }

  // Hide close button with fade animation
  hideCloseButton() {
    const button = this.closeButtonTarget;
    gsap.to(button, {
      opacity: 0,
      duration: 0.3,
      onComplete: () => {
        button.classList.add('hidden');
      },
    });
  }

  // Clear input field
  clearInput() {
    this.inputTarget.value = '';
    this.hideCloseButton();
    this.performSearch('');
  }
}
